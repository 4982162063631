import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import React from 'react';
import { shape } from 'prop-types';
import Delivery from '@magalu/stereo-ui/organisms/FilterDelivery';
import { filterByDelivery, filterByPromotion, buildFilterUrl, routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var FilterDelivery = function FilterDelivery(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data,
    structure = _ref.structure;
  var _ref2 = staticProps || {},
    hasBorder = _ref2.hasBorder,
    scrollOff = _ref2.scrollOff,
    promotionalOnly = _ref2.promotionalOnly;
  var _ref3 = (data == null ? void 0 : data.search) || {},
    filters = _ref3.filters;
  var deliveryFilter = filters == null ? void 0 : filters.find(filterByDelivery);
  var promotionFilter = filters == null ? void 0 : filters.find(filterByPromotion);
  var deliveryValues = (deliveryFilter == null ? void 0 : deliveryFilter.values) || [];
  var promotionValues = (promotionFilter == null ? void 0 : promotionFilter.values) || [];
  var query = structure.query;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var values = promotionalOnly ? _toConsumableArray(promotionValues) : [].concat(_toConsumableArray(promotionValues), _toConsumableArray(deliveryValues));
  var filteredByTopPosition = values == null ? void 0 : values.filter(function (item) {
    var _item$position;
    return item == null ? void 0 : (_item$position = item.position) == null ? void 0 : _item$position.includes('top');
  });
  var items = filteredByTopPosition == null ? void 0 : filteredByTopPosition.map(function (_ref4) {
    var label = _ref4.label,
      type = _ref4.type,
      selected = _ref4.selected,
      filterSlug = _ref4.slug;
    return {
      href: buildFilterUrl({
        query: query,
        selected: selected,
        structure: structure,
        type: type,
        unique: true,
        value: filterSlug
      }),
      label: label,
      selected: selected,
      slug: filterSlug
    };
  });
  var onChange = function onChange(_ref5) {
    var href = _ref5.href,
      eventLabel = _ref5.slug,
      selected = _ref5.selected;
    publish("delivery:filter:".concat(selected ? 'selected' : 'clear'), {
      eventLabel: eventLabel,
      structure: structure
    });
    routePush({
      path: href,
      spa: true
    });
  };
  return React.createElement(React.Fragment, null, items.length > 0 && React.createElement(Delivery, {
    filters: items,
    onChange: onChange,
    hasBorder: hasBorder,
    scrollOff: scrollOff
  }));
};
FilterDelivery.displayName = 'FilterDelivery';
process.env.NODE_ENV !== "production" ? FilterDelivery.propTypes = {
  data: shape({}),
  "static": shape({}),
  structure: shape({})
} : void 0;
FilterDelivery.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
export default withLayoutProps(FilterDelivery);
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["title", "data"];
import React from 'react';
import StereoFaq from '@magalu/stereo-ui/molecules/Faq';
import { shape, string } from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import withLayoutProps from '../../../hocs/withLayoutProps';
function Faq(_ref) {
  var _ref2;
  var _ref$static = _ref["static"],
    title = _ref$static.title,
    _ref$static$data = _ref$static.data,
    data = _ref$static$data === void 0 ? [] : _ref$static$data,
    props = _objectWithoutProperties(_ref$static, _excluded);
  var parsedData = (_ref2 = Array.isArray(data) ? data : []) == null ? void 0 : _ref2.map(function (_ref3) {
    var Answer = _ref3.Answer,
      Question = _ref3.Question;
    return {
      answer: Answer,
      question: Question
    };
  });
  return React.createElement(React.Fragment, null, title && React.createElement(Heading, {
    "data-testid": "faq-title",
    color: "base",
    as: "h2",
    fontSize: 5,
    fontWeight: "bold",
    lineHeight: "display",
    m: "0 8px 12px"
  }, title), React.createElement(StereoFaq, _extends({
    faq: parsedData
  }, props)));
}
Faq.defaultProps = {
  data: {},
  "static": {
    title: ''
  }
};
process.env.NODE_ENV !== "production" ? Faq.propTypes = {
  data: shape({}),
  "static": shape({
    title: string
  })
} : void 0;
Faq.ssr = true;
export default withLayoutProps(Faq);
import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import FastFilter from '@magalu/stereo-ui/organisms/FastFilter';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { filtersParser, routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { Container } from './FastFilters.styles';
var FastFilters = function FastFilters(_ref) {
  var data = _ref.data,
    structure = _ref.structure,
    staticProps = _ref["static"];
  var _ref2 = (data == null ? void 0 : data.search) || {},
    filtersData = _ref2.fastFilters;
  if (!filtersData) {
    return null;
  }
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var customCategoryFilter = false;
  var itemHeight = staticProps.itemHeight,
    itemWidth = staticProps.itemWidth,
    desktopMode = staticProps.desktopMode;
  var parsedFilters = filtersParser({
    customCategoryFilter: customCategoryFilter,
    data: data,
    filters: filtersData,
    query: {},
    removeFilters: [],
    structure: structure
  });
  var onFilterItemClick = function onFilterItemClick(e, filter) {
    e.preventDefault();
    publish('productlist:fastFilter:click', _extends({}, filter, {
      custom: false
    }));
    routePush({
      path: filter == null ? void 0 : filter.href,
      spa: true
    });
  };
  var onFilterView = function onFilterView(_ref3) {
    var _attributeFilters$, _attributeFilters$$va, _attributeFilters$$va2;
    var filters = _ref3.filters;
    var attributeFilters = filters.attributeFilters;
    var eventLabel = (_attributeFilters$ = attributeFilters[0]) == null ? void 0 : (_attributeFilters$$va = _attributeFilters$.values) == null ? void 0 : (_attributeFilters$$va2 = _attributeFilters$$va.flatMap(function (item, idx) {
      return "".concat(idx + 1, "\xB0").concat(item.label, ":").concat(item.slug);
    })) == null ? void 0 : _attributeFilters$$va2.join(';');
    publish('productlist:fastFilter:view', {
      custom: false,
      eventLabel: eventLabel
    });
  };
  return React.createElement(React.Fragment, null, filtersData && React.createElement(Container, null, React.createElement(FastFilter, {
    filters: parsedFilters,
    onItemClick: onFilterItemClick,
    onView: onFilterView,
    desktopMode: desktopMode,
    itemHeight: itemHeight,
    itemWidth: itemWidth
  })));
};
process.env.NODE_ENV !== "production" ? FastFilters.propTypes = {
  data: shape({}),
  "static": shape({
    desktopMode: bool,
    itemHeight: number,
    itemWidth: number,
    label: string
  }),
  structure: shape({})
} : void 0;
FastFilters.displayName = 'FastFilters';
FastFilters.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
FastFilters.ssr = true;
export default withLayoutProps(FastFilters);